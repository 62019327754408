@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

button.preview-button {
	-webkit-animation-fill-mode: backwards;
	        animation-fill-mode: backwards;
	display: none;
}

.preview-file {
	overflow: auto;
}

p:last-child {
  margin-bottom: 0;
}
.main_wrapper {
  display: flex;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
  background: #f5f5f5;
}

.left_pannel {
  width: 200px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logout a {
  color: #fff;
  text-decoration: none;
  background: #d60000;
  border: 1px solid #d60000;
  display: block;
  padding: 7px;
  border-radius: 50px;
  transition: all 0.7s;
}
.logout a:hover {
  color: #d60000;
  background: #fff;
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}
.main_content_panel {
  background: #f5f5f5;
  width: 100%;
  padding: 30px 15px;
  border-radius: 0;
  margin: 0 auto;
  min-height: calc(100vh - 70px);
}

.profile_logout {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.profile_img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #ffffff;
  box-shadow: 0 0 0px 3px #faaf19;
}

.profile_info h2 {
  font-size: 18px;
  margin-top: 20px;
}

.profile_info p {
  font-size: 15px;
  color: #777;
  margin-bottom: 7px;
}

/**********/

.header_title {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_title h1 {
  font-size: 30px;
  color: #78b1e0;
  margin-bottom: 0;
}
.header_title h4 {
  font-size: 30px;
  color: #78b1e0;
  margin-bottom: 0;
}
.header_title p {
  font-size: 20px;
  color: #777;
  margin-bottom: 0;
  font-weight: 500;
}
.header_title h1 span {
  font-weight: 300;
}

.dashboard_card {
  background: #fff;
  border-radius: 10px;
  padding: 22px;
  box-shadow: 0 0 13px #e2e2e2;
  height: 100%;
}
.intime {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time_btn button {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  border: 2px solid #15bb66;
  color: #15bb66;
  background: #f5fffa;
  transition: all 0.7s;
}

.time_input p span {
  font-weight: 400;
}

.time_input p {
  font-size: 18px;
  font-weight: 600;
  color: #777;
  text-align: right;
}

.break_input p {
  font-size: 18px;
  color: #777;
  text-align: right;
}

.time_btn button.btn_red {
  border: 2px solid #cf191b;
  color: #cf191b;
  background: #fff5f5;
}

.working_hours {
  text-align: center;
}
.working_hours p {
  font-size: 22px;
  color: #666;
}

button.req_btn {
  background: #79b2e1;
  border: 1px solid #79b2e1;
  padding: 7px 20px;
  border-radius: 6px;
  color: #fff;
  transition: all 0.7s;
}

button.req_btn:hover {
  background: #fff;
  color: #79b2e1;
}

.card_title {
  margin-bottom: 20px;
}

.card_title h4 {
  font-size: 24px;
  color: #666;
}

ul.important_date_list {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

ul.important_date_list li {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  align-items: center;
  font-size: 18px;
}

.date_item {
  background: #edf6ff;
  border-radius: 6px;
  margin-right: 10px;
  padding: 4px 8px;
}

.date_item h2 {
  font-size: 26px;
  margin-bottom: 0;
  color: #777;
}

.date_item h4 {
  font-size: 15px;
  color: #777;
  margin-bottom: 0;
}

.calender_heading {
  display: flex;
  justify-content: space-between;
}
.days {
  display: flex;
  flex-wrap: wrap;
}

.day {
  width: 14.28%;
  text-align: center;
}
.day.label {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.day span {
  width: 45px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  border-radius: 6px;
  background: #f9f9f9;
  margin-top: 20px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
}

.day.holiday span {
  background: #6d6d6d;
  color: #fff;
}

.day span:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background: #000;
}

.day.holiday span:after {
  background: #fff;
}

.day.prenent span:after {
  background: #17bb65;
}

.day.leave span:after {
  background: #ff0000;
}

.day.present_day span {
  background: #ffecca;
}

.dropdown-toggle.calender_view::after {
  display: inline-block;
  margin-left: 7px;
  vertical-align: 3px;
  content: "";
  border-top: 1px solid transparent;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid transparent;
  width: 10px;
  height: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.calender_view {
  background: #edf6ff !important;
  color: #444 !important;
  border: none !important;
  outline: none !important;
}

.calender_view:focus,
.calender_view:hover {
  background: #edf6ff;
  color: #444;
  border: none;
  box-shadow: none;
}

.weekly_calander ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.weekly_calander ul li {
  width: 14.28%;
  text-align: center;
}

.week_view {
  background: #f9f9f9;
  width: 70px;
  padding: 20px 10px;
  margin: 0 auto;
  border-radius: 6px;
  font-size: 18px;
}
.week_view span {
  width: 7px;
  height: 7px;
  background: #000;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}

.week_view.present span {
  background: #17bb65;
}

.week_view.leave span {
  background: #ff0000;
}

.week_view.holiday {
  background: #6d6d6d;
  color: #fff;
}

.week_view.holiday span {
  background: #fff;
}

.week_view.present_day {
  background: #ffecca;
}

footer p {
  text-align: center;
  margin-top: 30px;
  color: #777;
}

.Attendance_modification button.btn-close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0 0 10px #999;
}

ul.day_info {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.day_info li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.details_q {
  width: 40%;
}
.color_green {
  color: #15bb66;
}
.color_red {
  color: #ca0002;
}
.color_orange {
  color: #eeb242;
}
.color_blue {
  color: #86b1dd;
}
.btn-submit {
  background-color: #86b1dd !important;
  color: #fff !important;
}

/******************/

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #d9edff;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}
#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}

#login-form p.form-title {
  color: #333;
  font-size: 42px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}
#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.ant-form-item-control-input-content {
  text-align: left;
}
#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}
#login-form_username {
  height: 48px;
}
#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
.illustration-wrapper img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}

/**** ADMIN *******/

.employee_count {
  text-align: center;
}
.employee_count h2 {
  font-size: 48px;
  border: 3px solid;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  line-height: 0px;
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_card a {
  text-decoration: none;
}
.employee_count a h2 {
  color: #777;
}
.hovered_box {
  transition: all 0.6s;
}
.hovered_box:hover {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
}
.employee_count p {
  font-size: 20px;
  font-weight: 500;
  color: #777;
}

.text_blue {
  color: #86b1dd;
}
.text_green {
  color: #89c494;
}
.text_orange {
  color: #efb243;
}

.has-search .form-control {
  padding-right: 2.375rem;
  border-radius: 50px;
  color: #777;
}
.form-group.has-search {
  position: relative;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  right: 0;
}
.dashboard_card.employee_lists {
  background: #f3f7fb;
  padding: 0;
}

.dashboard_card.employee_lists .card_title.calender_heading {
  background: #fff;
  padding: 30px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0;
}

.filter_letters {
  background: #fff;
  border-radius: 0 0 10px 10px;
  padding: 0 30px 30px;
}

.filter_letters ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.filter_letters ul li a {
  text-decoration: none;
  color: #777;
  font-size: 16px;
  font-weight: 500;
}
.filter_letters ul li:hover a,
.filter_letters ul li.active a {
  color: #efb243;
}

.employee_table {
  padding: 30px;
  overflow-x: scroll;
}
 
span.request_empty {
  width: 22px;
  height: 22px;
  background: green;
  display: inline-block;
  margin: 0;
  border-radius: 50%;
}

.textCenter {
  text-align: center;
}

.table_btn {
  background: #f9b015;
  border-radius: 4px;
  border: none;
  color: #fff;
}
.table_btn_disable{
  background: #808080;
  border-radius: 4px;
  border: none;
  color: #fff;
}
span.request_empty.request_rised {
  background: #ff0000;
}

span.request_empty.break_status {
  background: yellow;
}

/**** ADMIN *******/

.time_btn {
  position: relative;
}
.time_btn button {
  display: block;
  background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
  position: relative;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0px 3px 8px #aaa, inset 0px 0px 13px #dadada;
}

.time_btn button:hover {
  box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff,
    0 0 20px rgb(120 177 225 / 50%);
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.time_btn:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;
  z-index: 0;
  border-radius: 50%;
  box-shadow: inset 0px 7px 28px #eaeaea;
}

/**********/
.user_profile {
  padding: 25px;
  border-right: 1px solid #eee;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.user_profile .profile_img {
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
}

.admin_heading {
  display: flex;
  justify-content: space-between;
  padding: 25px 25px 0px 0;
}
.time_spend h4 {
  color: #666;
  font-size: 18px;
}
span.timein {
  color: green;
}
span.timeout {
  color: red;
}
.time_spend {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}
.leaves_remain {
  background: #75c690;
  color: #fff;
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 17px;
  padding: 7px 20px;
  margin-top: 10px;
  margin-right: 10px;
}
.time_cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-between;
  padding-right: 20px;
}
.time_card_base {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
  width: calc(33.33% - 20px);
  padding: 20px;
  position: relative;
}
.edit_btn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.time_card_base h3 {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 20px;
}
.time_card_base p {
  font-size: 18px;
  font-weight: 500;
  color: #666;
}
.leave_status {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.btn-leave_status {
  background: #78b1e1 !important;
  color: #fff !important;
  text-transform: uppercase;
  box-shadow: 3px 5px 10px #ccc;
}

/*****************/

.admin_login-page {
  background-image: url(/static/media/admin_login_bg.b9ab27fe.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amdin_login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.amdin_login-box .illustration-wrapper {
  background: transparent;
  padding: 50px;
  width: 50%;
  max-width: 50%;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.amdin_login-box .illustration-wrapper h2 {
  font-size: 44px;
  text-transform: uppercase;
  margin-top: 30px;
  color: #78b1e1;
  font-weight: 600;
}

.amdin_login-box form#login-form {
  border-left: 1px solid #dadada;
  width: 50%;
  padding: 50px;
}

.login_layout {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
    rgb(0 0 0 / 10%) 0px 2px 4px 0px,
    rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
}

.input-group input {
  border: none;
  outline: none;
  box-shadow: none;
  min-height: 50px;
}

.input-group input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.input-group .input-group-prepend span {
  height: 50px;
  background: #faaf19;
  border: none;
  border-radius: 0;
  color: #fff;
  padding: 16px;
  width: 44px;
}

.forgot_pw {
  display: flex;
  justify-content: space-between;
}

.forgot_pw a,
.forgot_pw label {
  color: #777;
}

.select_role select.form-select {
  border: none;
  height: 40px;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px,
    rgb(0 0 0 / 10%) 0px 2px 4px 0px,
    rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
  color: #777;
  text-transform: uppercase;
}

.select_role {
  margin-bottom: 20px;
}

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}
.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}
.profile-pic .-label {
  cursor: pointer;
  height: 100px;
  width: 100px;
}
.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-pic span {
  display: inline-flex;
  /* padding: 0.2em; */
  font-size: 12px;
  height: 2em;
}
.employee-heading {
  background: #78b1e0;
  padding: 15px;
  text-align: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.employee-heading h1 span {
  font-weight: 500;
}
.employee-heading h1 {
  color: #fff;
  font-size: 28px;
}
.employee-subheading, .deduction-subheading {
  font-size: 20px;
  color: #78b1e0;
  background: #f2f9ff;
  padding: 10px 15px;
}
.employee_earning {
  border-top: 2px solid #efefef;
  margin: 1rem 1.5rem;
  padding-top: 20px;
}
/* .deduction-subheading {
  margin-bottom: 15px;
  color: #78b1e0;
  font-size: 22px;
  padding-top: 20px;
  margin-top: 15px !important;
  border-top: 2px solid #efefef;
} */
.e-submit-detail {
  padding: 10px 20px !important;
  background: #87d6a0 !important;
}
.main-add-employee{ 
background: linear-gradient(26deg, rgba(255,255,255,1) 41%, rgba(245,249,253,1) 0%);
background-attachment: fixed;
}
@media screen and (max-width: 992px) {
  #login-form {
    flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 10px;
  }
  #login-form p.form-title {
    font-size: 32px;
  }
  #login-form {
    max-width: 100%;
    padding: 10px !important;
  }
  .illustration-wrapper {
    max-width: 100% !important;
    min-height: auto;
    width: 100% !important;
    padding: 15px !important;
  }
  .amdin_login-box .illustration-wrapper h2 {
    font-size: 26px;
  }
  .main_content_panel {
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  .left_pannel {
    width: 100%;
    padding: 10px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .logo img {
    height: 40px;
  }

  .profile_info {
    margin-bottom: auto;
    display: flex;
    align-items: center;
  }
  .profile_img {
    width: 180px;
    height: 180px;
    margin: 0 0px 0px 0;
    box-shadow: 0 0 0px 2px #faaf19;
  }
  .profile_info h2 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
  }
  .logout a {
    font-size: 0;
    margin-left: 15px;
    padding: 4px 0;
  }
  .logout a i {
    color: #fff !important;
    font-size: 20px;
    padding: 2px 3px;
  }
  .filter_letters ul li {
    width: 7.69%;
  }
  .employee_table {
    overflow: auto;
  }
  .filter_letters ul {
    flex-wrap: wrap;
  }
  .logout a:hover i {
    color: #d60000 !important;
  }

  .employee_count h2 {
    font-size: 38px;
    width: 90px;
    height: 90px;
    margin: 0 auto 10px;
  }

  .dashboard_card {
    padding: 15px;
  }

  .logout a span svg {
    font-size: 16px;
  }
  .profile_logout {
    min-height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .header_title h1 {
    font-size: 24px;
  }
  .dashboard_card.employee_lists .card_title.calender_heading {
    padding: 15px;
    flex-direction: column;
  }
  .filter_letters {
    padding: 0 15px 15px;
  }
  .dashboard_card.employee_lists .card_title.calender_heading h4 {
    margin-bottom: 20px;
  }
  .dashboard_card.employee_lists .card_title.calender_heading .d-flex button {
    white-space: nowrap;
  }
  .employee_table th {
    white-space: nowrap;
  }
  .table_btn {
    margin: 4px;
  }
  .employee_count p {
    font-size: 16px;
  }
  .time_cards {
    padding-right: 20px;
    padding-left: 20px;
  }
  .admin_heading {
    padding: 25px 25px 0px 25px;
  }
  .time_spend {
    padding: 0px 25px 15px;
  }
  .time_card_base {
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 20px -40px,
      rgb(0 0 0 / 30%) 0px 10px 10px -60px,
      rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
  }
}
@media screen and (max-width: 767px) {
  .user_profile {
    padding: 15px;
    border-right: none;
    border-bottom: 1px solid #eee;
  }
  .admin_heading {
    padding: 15px 15px 10px 15px !important;
    flex-direction: column;
    text-align: center;
  }
  .time_spend {
    text-align: center;
    padding: 0 15px 15px;
  }
  .time_cards {
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
  }
  .time_card_base {
    width: 100%;
    margin-bottom: 30px;
  }
  .time_card_base:last-child {
    margin-bottom: 0;
  }
  .leave_status h5 {
    width: 100% !important;
    margin-bottom: 15px !important;
    font-size: 14px;
    margin-right: 0 !important;
  }
  .leave_status {
    flex-direction: column;
    text-align: center;
  }
  .leave_btns {
    display: flex;
  }
  .calender_heading .btn-groups {
    display: flex;
  }
  .modal-body .form-control {
    margin-bottom: 15px;
  }
}

/*----- Componant: Top Navigation Bar ----- */
.main-header {
  background-color: #1d4354;
  width: 100%;
}
.app-header {
  width: 100%;
  display: flex;
  font-size: 14px;
  position: relative;
  align-items: center;
}
.app-header .user-img {
  object-fit: cover;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
}
.app-header .app-logo img {
  max-width: 30px;
}
.app-notification__meta {
  color: #6c757d !important;
  font-size: 12px;
}
.app-header__logo {
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  font-family: "Niconne";
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
}
.app-header__logo:focus,
.app-header__logo:hover {
  text-decoration: none;
  color: #fff;
}

.app-sidebar__toggle {
  padding: 0 15px;
  font-family: fontAwesome;
  color: #fff;
  line-height: 2.4;
  transition: background-color 0.3s ease;
}

.app-sidebar__toggle:before {
  content: "\f0c9";
  font-size: 21px;
}

.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
  color: #fff;
  background-color: #00635a;
  text-decoration: none;
}

.app-nav {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 auto;
}
.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #fff;
  transition: background-color 0.3s ease;
}

.app-nav__item:hover,
.app-nav__item:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.app-search {
  position: relative;
  display: flex;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
  display: none;
}

.app-search__input {
  border: 0;
  padding: 5px 10px 5px 20px;
  padding-right: 30px;
  border-radius: 50px;
  background-color: #2b5468;
  transition: background-color 0.3s ease;
  color: #fff;
  outline: none;
}

.app-search__input::-webkit-input-placeholder {
  color: #fff;
}

.app-search__input::placeholder {
  color: #fff;
}

.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: #fff;
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
  padding-top: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}

.app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-notification__item {
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.app-notification__item:focus,
.app-notification__item:hover {
  color: inherit;
  text-decoration: none;
  background-color: #e0e0e0;
}

.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 70px;
  width: 230px;
  overflow: auto;
  z-index: 10;
  background-color: #222d32;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease, width 0.3s ease;
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-sidebar__user {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
}

.app-sidebar__user-avatar {
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
}

.app-sidebar__user-name,
.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.app-menu {
  margin-bottom: 0;
  padding-bottom: 40px;
  padding-left: 0;
}

.app-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  font-size: 1.08em;
  border-left: 3px solid transparent;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  color: #fff;
}

.app-menu__item.active,
.app-menu__item:hover,
.app-menu__item:focus {
  background: #0d1214;
  border-left-color: #78b1e0;
  text-decoration: none;
  color: #fff;
}

.app-menu__icon {
  flex: 0 0 auto;
  width: 25px;
}

.app-menu__label {
  white-space: nowrap;
  flex: 1 1 auto;
}
.app-header ul li {
  list-style: none;
  position: relative;
}
.app-header .dropdown-menu {
  font-size: 0.875rem;
}
.app-header a {
  text-decoration: none;
  color: inherit;
}
.app-nav__item i {
  color: #fff;
}
.app-header .nav-link {
  color: #fff;
}
.submenu:hover .dropdown-menu {
  display: block;
}
.submenu:hover .nav-link:after {
  text-decoration: underline;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.notifications .app-nav__item {
  padding-top: 25px;
  padding-bottom: 25px;
}
.app-header .dropdown-menu::after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  right: 10px;
  border: 8px solid #fff;
  border-color: transparent transparent #fff #fff;
  -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
  box-shadow: -2px 2px 3px rgb(57 73 76 / 10%);
  z-index: 9999;
}

.user-notify-data .dropdown-menu::after{
  border-color: transparent transparent #faaf19 #f9af16;
}


.app-menu__item.active,
.app-menu__item:hover,
.app-menu__item:focus {
  background: #ffffff;
  border-left-color: #78b1e0;
  text-decoration: none;
  color: #1d4354;
}

.nav-overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(29 67 84);
  overflow-x: hidden;
  transition: 0.5s;
}

.nav-overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  margin-top: 30px;
}

.nav-overlay a {
  color: #fff;
}
.nav-overlay .closebtn {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 30px;
}
.treeview.is-expanded .treeview-menu {
  max-height: 100vh;
}
.treeview-menu {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background: #2a383e;
}
.treeview.is-expanded .treeview-indicator {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.treeview-item {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 20px;
  font-size: 1em;
  color: #fff;
}
.treeview-item.active,
.treeview-item:hover,
.treeview-item:focus {
  background: #fff;
  text-decoration: none;
  color: #1d4354;
}
.treeview-item .icon {
  margin-right: 5px;
}
.mobile-icon span {
  color: #ffff;
  font-size: 22px;
  margin-right: 25px;
  cursor: pointer;
}
.active_btn {
  background: #78b1e1 !important;
  color: #fff !important;
}

@media (max-width: 767px) {
  .app-search {
    display: none;
  }
  .app-sidebar__toggle {
    order: -1;
  }
  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
  .app-desktop li.nav-item {
    display: none;
  }
}

@media (min-width: 768px) {
  .app-header {
    padding-right: 30px;
  }
  .app-header__logo {
    flex: 0 0 auto;
    display: block;
    width: 230px;
    background-color: #007d71;
  }
  .mobile-icon {
    display: none;
  }
}

.leave-history-table table {
  font-size: 13.5px;
}
.leave-history-table table th {
  white-space: nowrap;
  vertical-align: middle;
}
.leave-history-table {
  padding: 15px;
}
.employee_table table th:last-child {
  white-space: normal;
}
.employees-leaves-page {
  min-height: 89vh;
}
.app-header .nav-link:hover,
.app-header .nav-link:focus,
.app-header .nav-link:active {
  color: #faaf18;
}
.employee-list-table {
  font-size: 14px;
}

.ck-editor__editable_inline {
  min-height: 200px;
}
.profile-pic.imageUploadClass:hover .-label,
.profile-pic.imageUploadClass img {
  border-radius: 0;
}

.profile-pic.imageUploadClass {
  overflow: hidden;
}

.profile-pic.imageUploadClass .-label {
  width: 100%;
}

.profile-pic.imageUploadClass img {
  width: 100%;
  height: auto;
}

button#dropdownMenu2 {
  background: transparent;
  padding: 0;
  border: none;
  box-shadow: none;
  outline: none;
}

.pending_leaves {
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  /* background: #9fd6b1; */
  text-align: center;
}
.edit_pending_leaves {
  width: 50px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  background: #9fd6b1;
  text-align: center;
}

.allotted_leaves {
  font-size: 12px;
  color: #908f8f;
  padding: 0 10px;
}

.history_leaves_pending {
  background: #75c690;
  color: #fff;
  border-radius: 4px;
}

.history_allotted_leaves {
  font-size: 12px;
  color: white;
  padding: 0 10px;
}
.main-uploadimage {
  position: relative;
}
.main-uploadimage .fa-close {
  color: #fff;
  font-size: 15px;
  position: absolute;
  top: -7px;
  z-index: 1001;
  right: -7px;
  background: #000;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.rounded-50 {
  border-radius: 50px;
}
.dropdown_icon {
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown_icon:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 5px;
}

.user-details-overlay .user-image {
  width: 120px;
  height: 120px;
}
/** dashboard design **/

.heading-title-wrap {
  border-bottom: 1px solid #c3c3c3;
}
.employee_profile input,
.employee_profile select,
.employee_profile textarea {
  font-size: 14px;
}
.employee_profile label{
  font-weight: 600;
}
.employee_profile label {
  font-weight: 500;
  font-size: 14px;
}
.heading-title-info {
  color: #faaf19;
  text-transform: capitalize;
}
#file~.text-muted {
  font-size: 12px;
}

/* Notification cSs */




.app-header ul li {
  list-style: none;
  position: relative;
}
.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgb(249 175 22);
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}
.app-header a {
  text-decoration: none;
  color: inherit;
}
.app-notification__item {
  display: flex;
  padding: 8px 0px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}
.app-notification__icon {  
  padding-right: 5px;
}
.app-notification__message, .app-notification__meta {
  margin-bottom: 0;
}
.app-notification__meta {
  color: #6c757d !important;
  font-size: 12px;
}
.app-notification__message {
  line-height: 1.2;
  font-size: 14px;
  color: #333;
  font-size: 13px;
    margin-bottom: 3px;
}




/* new added */




.app-notification__item, .app-notification__item a {
  text-decoration: none;
}
.app-notification__content li {
  padding: 0 5px;
}
.app-notification__icon .text-primary {
  color: #7ab3e1!important;
}


/** dashboard design end **/


.generate-form-list-main{
  height: calc(100vh - 86px);
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

.error{
  color: red;
}
.profile_img  img {
  object-fit: cover;
  width: 100%;
  height: 118px;  
}

.hide-scroll{
  padding-top: 0;
  padding-bottom: 0;
}
.hide-scroll .employee-heading {
  margin-top: 15px;
}

.add_employee-subheading {
  display: flex;
  justify-content: space-between;
}
.add_employee-subheading button{
  background: #78b1e0;
  border: none;
  color: #f2f9ff;
  border-radius: 4px;
  min-width: 25px;

}

.employee_details_data button.nav-link.disabled_sidemenu {
  background: #f8f8f8 !important;
  color: #a8a8a8 !important;
}

.employheadreForm {
  background: #f2f9ff;
  padding: 12px 15px;
  border-radius: 6px 6px 0 0;
}

.disable_fields {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.tab_minheight {
  max-height: calc(100vh - 15rem);
  min-height: calc(100vh - 15rem);
  overflow-y: auto;
}
.candidateTable {
  vertical-align: bottom;
  background: #76c690;
}
.candidateTable-hover > tbody > tr:hover > * {
  background-color: #f2fff6;
  color: var(--bs-table-hover-color);
}
.div-form-select {
  width: 344px;
  height: 41px;
  border-color: hsl(0, 0%, 80%);
}

.message-dropdown div:hover {
  background-color: #d3d3d3;
}
/** Custom css **/

.project-heading-info {
    white-space: nowrap;
    width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.project-description-info {
    min-height: 120px;
    max-height: 120px;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.edit_project_btn {
    padding: 6px !important;
    line-height: 0;
}

.project-userimg-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cus-row-wrap .col-md-2 {
    background-color: #fff;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 32%);
}

.card_height {
    height: 38vh;
}

/** Projects update cSs **/

.project-updates-content-info,
.content-wrap {
    display: flex;
    align-items: center;
}

.discussions-list-content-info {
    display: flex;
    align-items: flex-start;
}

.projects-update-wrapper a {
    text-decoration: underline;
    font-size: 14px;
}

.head-title-info {
    text-transform: capitalize;
}

.description-info {
    font-size: 14px;
    color: #a9a5a5;
}

.col-red {
    color: #ad4242;
}

.date-info {
    font-size: 14px;
}

.project-updates-content-info,
.discussions-list-content-info {
    font-size: 14px;
}

.post-new-message-wrap .btn {
    font-size: 12px;
}

.discussions-list-content-info .follow-up-wrap {
    flex-basis: 70%;
    max-width: 70%;
    padding: .25em 1em .25em 0;
}

.discussions-list-content-info .user-name-wrap {
    flex-basis: 15%;
    max-width: 15%;
    padding: .25em 1em .25em 0;
}

.discussions-list-content-info .date-wrap {
    flex-basis: 15%;
    max-width: 15%;
    padding: .25em 0;
}

.img-wrap img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: 5px;
}

.text_box_outline:focus {
    border: none !important;
    box-shadow: none !important;

}

.discussions-list-wrap {
    padding: 5px;
    border: 2px solid #fff;
    border-radius: 5px;
    margin: 5px 0;
}

.discussions-list-wrap:hover {
    background: #f7fafb;
    box-shadow: 0 0 10px #39547c;
}

.pu-message-wrap p:first-child {
    white-space: nowrap;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
    margin-bottom: 20px;

}

.pu-message-wrap li,
.pu-message-wrap ol {
    max-width: 95%;
    list-style: auto;

}

.pu-message-wrap {
    max-height: 41px;
    overflow: hidden;
}

/** Projects update end cSs **/

.full-selectbox .css-1qgnntc-container {
    width: 100%;
}

.main_content_panel.main_content_panel2 {
    background-color: #fff;
    margin: 20px;
    padding: 22px;
    box-shadow: 0 0 13px #e2e2e2;
    border-radius: 10px;
}

.main_content_panel.main_content_panel2 .dashboard_card {
    padding: 0;
    box-shadow: none;
}

.main_content_panel.main_content_panel2 .previous-chat {
    overflow-y: scroll;
    height: 100vh;
}

/*** Media screen ***/
@media screen and (min-width:1366px) {
    .cus-row-wrap .col-md-2 {
        /* width: 18.75%; */
        width: 23.5%;
    }
}

@media screen and (max-width:1365px) {
    .cus-row-wrap .col-md-2 {
        width: 18.5%;
    }


}

.follow-up-info_name {
    font-weight: bolder;
}

/* project-updates-info */
.project_updates_info #pills-profile-tab {
    max-height: 35px;
    display: flex;
    align-items: center;
}

.project_discussions_list {
    background: whitesmoke;
    padding: 20px;
    border-radius: 10px;
}

/* .modal,.modal-backdrop.show{
    z-index: 999 !important;
}

.customModal{
position: fixed;
top: 50%;
left: 50%;
height: 100vh;
transform: translate(-50%, -50%);
width: 50%;
display: flex;
align-items: center;
width: 100%;
pointer-events: auto;
background-color: #0000007a;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 0.3rem;
outline: 0
}
.customModal .modal-lg {
    margin: 1.75rem auto;
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    max-width: 800px;
    width:100%;
} */

body {
    --ck-z-default: 100;
    --ck-z-modal: calc(var(--ck-z-default) + 999);
}

.ck-body-wrapper {
    z-index: 1300 !important;
}

.message-dropdown {
    box-shadow: none !important;
    border: none !important;
}

.message-dropdown input[type="text"] {
    width: 20px;
    height: 40px;
    margin-bottom: 5px;
    border: 1px solid #cccccc;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
    padding-left: 9px;
    line-height: 40px;
}

.message-dropdown div:hover {
    background-color: transparent;
}

.message-dropdown .message-inner-drop.user-exists {
    border: 1px solid #ddd !important;
    border-radius: 5px;
}

.message-dropdown .message-inner-drop.user-exists .message-option:hover {
    /* background-color: #deebff !important; */
    background-color: #e0e0e0 !important;
}

.message-dropdown .message-inner-drop {
    border: none !important;
}

.message-dropdown input[type="text"]:focus {
    border: 2px solid #2684FF;
}

.btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}
/* width */
::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #919191; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919191; 
  }
  
.form-select-view{
    height: 38px;
    border-color: hsl(0, 0%, 80%);
  }
  .main_content_panel.main_content_panel2.subjec-content .previous-chat {
    height: 758px;
}

.text-editor .ck.ck-content.ck-editor__editable {
    height: 280px;
}
.main_content_panel.main_content_panel2.project-upadts-con .previous-chat {
    height: 530px;
}
.project-updates-content-info .date-info{
    min-width: 90px;
}
.thread-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.thread-name button {
    padding: 0;
    min-width: 30px;
    min-height: 30px;
}
@media only screen and (max-width: 992px) {
    .main_content_panel.main_content_panel2.main_content_panel3 {
        width: 100%;
    }

    .main_content_panel.main_content_panel2.main_content_panel3 .previous-chat {
        height: initial;
    }

    .main_content_panel.main_content_panel2.main_content_panel3 {
        width: 96%;
        margin: auto;
        margin-left: auto;
    }

    .main_content_panel.main_content_panel2 {
        margin: 0px;
    }

    .main_follow_info h6 {
        font-size: 14px;
    }

    .main_follow_info .chat-btn {
        margin-top: 32px;
    }

    .main_content_panel.main_content_panel2 .previous-chat {
        height: auto;
    }
    .main_content_panel.main_content_panel2.subjec-content .previous-chat {
        height: auto;
    }
    .main_content_panel.main_content_panel2.project-upadts-con .previous-chat {
        height: auto;
    }
}
.admin_login-page {
  background-image: url(/static/media/admin_login_bg.b9ab27fe.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
    justify-content: center;
    align-items: center;
}

.amdin_login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.amdin_login-box .illustration-wrapper {
  background: transparent;
  padding: 50px;
  width: 50%;
  max-width: 50%;
  height: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.amdin_login-box .illustration-wrapper h2 {
  font-size: 44px;
  text-transform: uppercase;
  margin-top: 30px;
  color: #78b1e1;
  font-weight: 600;
}

.amdin_login-box form#login-form {
  border-left: 1px solid #dadada;
  width: 50%;
  padding: 50px;
}

.login_layout {
  background: #fff;
  border-radius: 4px;
    margin-bottom: 15px;
    overflow: hidden;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px, rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;

}

.input-group input {
  border: none;
  outline: none;
  box-shadow: none;
  min-height: 50px;
}

.input-group input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.input-group .input-group-prepend span {
  height: 50px;
  background: #faaf19;
  border: none;
  border-radius: 0;
  color: #fff;
  padding: 16px;
  width: 44px;
}

.forgot_pw {
  display: flex;
  justify-content: space-between;
}

.forgot_pw a, .forgot_pw label{
  color: #777;
}

.select_role select.form-select {
  border: none;
  height: 40px;
  box-shadow: rgb(0 0 0 / 20%) 0px 12px 28px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px, rgb(255 255 255 / 5%) 0px 0px 0px 1px inset;
  color: #777;
    text-transform: uppercase;
}

.select_role {
  margin-bottom: 20px;
}

button.req_btn {
  background: #79b2e1;
  border: 1px solid #79b2e1;
  padding: 7px 20px;
  border-radius: 6px;
  color: #fff;
  transition: all .7s;
}

button.req_btn:hover {
  background: #fff;
  color: #79b2e1;
}

.employee_details_data {
    height: 100%;
  }
  .employee_details_data .nav {
    height: 100% !important;
    background: #eaf5ff;
    padding: 0;
  }
  .employee_details_data .nav .nav-link {
    background: transparent !important;
    text-align: left;
    border-radius: 0;
    border-bottom: 1px solid #b2e8ff;
    width: 100%;
    padding: 15px;
    color: #777;
    font-size: 18px;
  }
  .employee_details_data .nav .nav-link.active {
    color: #fab018;
    font-weight: 600;
    border-color: #faaf15;
  }
  
  @media only screen and (max-width: 767px) {
    .employee_details_data .nav {
      height: auto !important;
      margin-bottom: 20px;
      padding: 0;
  }
  }
.grid-box {
  height: calc(100vh - 8rem);
  overflow: auto;
}
.drop_area {
  height: 60px !important;
  width: 77%;
}

.treeview {
  padding: 4px 5px 0px !important;
}
/* span:has(> div.menu-trigger) {
  display: inline-flex;
  align-items: center;
} */
.file-node-item {
  display: flex;
  height: 20px;
  margin: 4px 0px 8px;
}
.file-node-item svg {
  width: 15px;
  margin-right: 10px;
}

div:has(> div.file-node-item) {
  padding: 8px 0px 8px 8px;
}

.text-center{
  text-align: center;
}

.main-section {
  height: calc(100vh - 9rem);
  overflow: hidden;
  background-color: transparent;
}
.main-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}
.main-section > aside {
  order: 0;
  flex: 0 0 auto;
  align-self: auto;
}
.main-section > main {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
}
.main-section > aside,
.main-section > main {
  padding: 10px;
  overflow: auto;
}
.resize-handle--x {
  opacity: 0.5;
  flex: 0 0 auto;
  position: relative;
  box-sizing: border-box;
  width: 3px;
  height: calc(100vh - 10rem);
  margin-top: 12px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  cursor: ew-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.resize-handle--x:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 100%;
  height: 18px;
  width: 2px;
  margin-top: -9px;
  border-left-color: black;
  border-left-width: 1px;
  border-left-style: solid;
}
.resize-handle--x:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  height: 18px;
  width: 2px;
  margin-top: -9px;
  border-right-color: black;
  border-right-width: 1px;
  border-right-style: solid;
}

.main-section .grid-box{
  height: calc(100vh - 10rem);
  overflow: auto;
}
aside.sidebar{
  max-width: 350px;
  width: 210px;
  min-width: 210px;
  padding-right: 0;
}
main.app-content {
  overflow: hidden;
}

.pdf-file-icon svg {
  max-width: 20px !important;
  margin-right: 10px;
}
.file-type-icons .fa-folder {
  font-size: 20px;
  color: #5f6368;
}
.file-type-icons,.pdf-file-icon{
  width: 24px;
  display: inline-block;
}
.pdf-file-icon{
  margin-right: 10px;
}
.row-highlight:hover {
  background-color: #ecece4;
}

.selected{
  background-color: #8dd6db;
}
.modal-width {
  width: 500px !important;
}

#style-3::-webkit-scrollbar-track
{
 box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 2px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

.modal-width {
  width: 600px !important;
}


.grid-table-heading {
  position: -webkit-sticky;
  position: sticky;
  /* top: 0; */
  top: 58px;
  background: #fff;
  padding: 5px 10px;
}

.grid-table-body {
  padding-left: 10px;
  padding-right: 10px;
}
.grid-table-body .row-highlight{
  margin: 0;
}

.menu-trigger > .selected-tree-node{
  color: #185acb !important;
  background-color: #e8f0fe;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-right:8px;
}

/* .treeview :has(> span > .menu-trigger > .selected-tree-node) {
  background-color: #e8f0fe;
  /* color: #185acb; */
  /* padding: 0px px; */
  /* border-top-right-radius: 15px;
  border-bottom-right-radius: 15px; */
/* } */ 
.header_title_docs h1 {
    font-size: 30px;
    color: #78b1e0;
    margin-top: 20px;
    margin-bottom: 10px;
 
}

.drag_drop_section .drop_area {
  max-width: 600px;
  width: 100%;
  height: calc(100vh - 22rem) !important;
  margin: auto;
  background: #e9f3fd;
  border-color: #dfe1e5;
  justify-content: center;
  flex-direction: column;
}
.employee_document_verification{
  width: 100% !important;
}
.drag_drop_section svg {
  margin-top: 6.5rem;
}
.file_submit_section {
  max-width: 600px;
  margin: 15px auto 10px auto;
  text-align: center;
}
.file_submit_section .btn,.file_submit_section .btn:hover{
  padding: 6px 25px;
  background: #76c690;
  border-color: #76c690;
}
.main_breadcrumb{ 
position: -webkit-sticky; 
position: sticky;
top: 0;
z-index: 2;
background: #ffff;
padding: 10px 20px;
}
aside.sidebar{
  overflow: hidden;
}
#dropdown-basic-button {
  background: transparent;
  color: #0d6efd;
}
#dropdown-basic-button:hover {
  background: #0d6efd;
  color: #fff;
}
.drag_drop_section .loader {
  position: absolute;
  border: none;
  background: transparent;
    -webkit-animation: none;
            animation: none;
}
.loader {
  position: absolute;
  border: none;
  background: transparent;
    -webkit-animation: none;
            animation: none;
}
 .breadcrumb-item a {
  color: #7ab3e1;
  text-decoration: none;
}
.preview-file{
  overflow: hidden;
}
.pg-viewer-wrapper {
  overflow-y: hidden !important;
}
/* .breadcrumb > li + li:before {
  content: "\3E"
}  */
.main_breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\f105";
  font-family: 'FontAwesome';
}
.drag_drop_section {
  position: relative;
}
.photo-viewer-container, 
.photo-viewer-container img {
height:100% !important;
width:100% !important;
}

@media only screen and (min-width: 768px) {
  .loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background:rgba(219,219,219,255/20%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top:0px;
    left:0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background:rgba(219,219,219,255/20%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top:0px;
    left:0px;
  }
}

 
.preview-bar-left{
float:right;
}
.preview-bar-left:first-child, .preview-icons button:last-child{
  display: none;
}
.preview-bar-left button, .preview-icons button {
  background: #d9e1ff;
  border: none;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.preview-bar-left button:hover, .preview-icons button:hover { background: #d9e1ff; }

.preview-icons {
  margin: 10px auto;
  text-align: center;
}
.preview-bar-left button:hover, .preview-icons button:hover{
  padding: 4px;
  background: #fff;
  border: 1px solid #1c2972;
  box-shadow: 0 0 4px #4e96f5;
}


/* With dots folder name*/

.submenu-content {
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
   display: block;
}
.treeview .fa-caret-down, .treeview .fa-caret-right {
  position: absolute;
  left: 0;
  top: 6px;
} 
.treeview {
 padding-left: 15px !important;
}
span:has(> div.menu-trigger) {
  align-items: center;
} 

.ContextMenuItem {
  pointer-events: auto;  /* Ensure that pointer events are enabled */
}
.form-dropdown.link-accessblity .dropdown-content ul {
  list-style: none;
  padding: 0px 0px;
  margin: 0;
}
.form-dropdown.link-accessblity .dropdown-content ul li {
  white-space: nowrap;
  padding: 6px 12px;
  margin: 0px 0px;
  margin-bottom: 2px;
} 
.form-dropdown.link-accessblity .dropdown-content ul li.active{
  color: #1e2125;
  background-color: #ddd;
}
.form-dropdown.link-accessblity .dropdown-content ul li:hover {
  color: #1e2125;
  background-color: #ddd;
}
.form-dropdown.link-accessblity .dropdown-submenu:after {
  z-index: -1;
}

.copy-link-document table {
  width: 100%;
  max-width: 100%;
  margin: auto;
}
.copy-link-document {
  width: 100%;
  max-width: 96%;
  margin: auto;
  padding: 70px 40px 50px 40px;
  margin-top: 80px;
  border-radius: 10px;
  position: relative;
  border: 1px solid #ddd;
}
.link-document-logo {
  position: absolute;
  left: 50%;
  top: -22px;
  -webkit-transform: translate(-50%, -26%);
          transform: translate(-50%, -26%);
  background-color: #fff;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.link-document-logo img {
  position: relative;
  left: 4px;
  top: -2px;
}
.copy-link-document p.no-records {
  font-size: 19px;
  font-weight: 400;
  color: #919191;
}
.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
}
.form-dropdown {
  position: relative;
  display: inline-block;
  width:100%;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}

.btn-toolbar {
  padding-bottom: 10px;
}
.inputWidth, .textAreaWidth, .radioButtonWidth{
  width: 40%;
  text-align: left;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content .linkForm {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content .linkForm:hover {background-color: #ddd;}
.form-dropdown:hover .dropdown-content {display: block;}
.dropdown-submenu{
  top: 0;
  left: 102%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  padding: 10px 0;
}
.dropdown-submenu:after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 3px;
  border: 8px solid #fff;
  border-color: transparent transparent #fff #fff;
  -webkit-transform: translateX(-50%) rotate(40deg);
          transform: translateX(-50%) rotate(40deg);
  box-shadow: -2px 2px 3px rgb(57 73 76 / 10%);
  z-index: 9999;
}
.dropdown-submenu .linkForm {
  padding: 4px 16px;
}


.generate_form button {
  margin-right: 30px;
}
.generate_form .css-6fuiu7-container,.users-box {
  width: 100%;
}
.generate_form .container{
  background: #fff;
  margin-top: 30px;
  border-radius: 4px;
  padding: 15px 20px 20px 20px;
}

.generate_form {
  width: 100%;
  padding: 15px;
  height: calc(100vh - 70px);
  max-width: 600px;
  margin: auto;
}
.generate_form  h4 {
  color: #79b2e0;
}
.generated-form-heading{
  color: #79b2e0;
}
.details-output-form {
  width: 100%;
  max-width: 900px;
  margin: 15px auto;
  background: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  position: relative;
  box-shadow: 0 0 13px #d4eafd;
}
.details-output-form > tr {
  border-bottom: 1px solid #ccc;
}
.tableform-heading{
  max-width: 900px;
  margin: auto;
}
.details-output-form  tr:nth-child(even) {
  background-color: transparent;
}
.details-output-form td{
  padding-left: 20px;
  padding-right: 20px;
}
.details-output-form .quiz {
  padding-top: 8px;
}
.details-output-form .answer {
  font-weight: 600;
}
.details-output-form tr:last-child table {
  margin-bottom: 10px;
}
.tableform-heading h2 {
  font-size: 30px;
  font-weight: 500;
  color: #79b2e0;
}
.generated_form_wrapper{
  white-space: nowrap;
}
.generated_form_table {
  max-height: calc(100vh - 17rem);
}
.generated_form_wrapper table th {
  /* position: sticky;
  top: 0; */
  background: #fff;
  border-left: 1px solid #c1c1c1;
  z-index: 2;
  /* box-shadow: -5px 0 0 0 rgba(0, 0, 0, .25); */
}
.generated_form_wrapper table thead{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.report_table tr th:first-child{
  border-left: 1px solid #c1c1c1;
}
.generated_form_wrapper table tbody {
  border-top: 0 !important;
}
.sub-quiz-listing {
  margin-bottom: 5px;
}
.sub-quiz-listing li {
  font-weight: 500;
}
.dropdown_icon {
  height: 34px;
}
.generated_form_table th {
  vertical-align: middle;
}



.feedback-form-wapper {
  width: 100%;
  max-width: 700px;
  margin: 15px auto;
  background: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  position: relative;
  box-shadow: 0 0 13px #d4eafd;
}
.feedback-form-wapper h2 {
  text-align: center;
  color: #79b2e0;
  font-weight: 600;
}
.feedback-form-wapper .w-50 {
  width: 100% !important;
}
.feedback-form-wapper .formio-component-submit {
  text-align: center;
  margin-top: 10px;
  /* margin-right: -224px; */
}
.feedback-form-wapper .back_btn {
  position: absolute;
  bottom: 20px;
  left: 19.5%;
}
.feedback-form-wapper .back_btn .btn,.feedback-form-wapper .formio-component-submit .btn{
   width: 200px;
}
.feedback-form-wapper .formio-component-submit .btn[disabled] {
  background: #91b5eb;
  border-color: #91b5eb;
}
.feedback-form-wapper .input-group-text {
  min-height: 38px;
}
.forms_card {
  background: #fff;
  border: none;
  font-weight: 700;
  box-shadow: 0 0 13px #e2e2e2;
}
.forms_card a{
  color: #fab325 !important;
  font-size: 18px;
  font-weight: 500;
}
.forms_card h4 {
  font-size: 24px;
  color: #666;
  margin-bottom: 10px;
}

.feedback-main-wrapper {
  width: 100%;
  background: rgb(255,255,255);
  background: linear-gradient(26deg, rgba(255,255,255,1) 53%, rgba(245,249,253,1) 0%);
  background-attachment: fixed;
}

.feedback-leftside {
  /* position: sticky;
  top: 0; */
  padding-top: 15px;
}
.feedback-leftside h2 {
  font-size: 32px;
  font-weight: 500;
  color: #7ab3e1;
  margin-top: 30px;
}
.feedback-form-wapper .formio-component-name, .feedback-form-wapper .formio-component-employeeId,.feedback-form-wapper .formio-component-designation,.feedback-form-wapper .formio-component-reportingManager,.feedback-form-wapper .formio-component-dateOfJoining,.feedback-form-wapper .formio-component-dateOfRelieving,.feedback-form-wapper .formio-component-department{
  width: 50% !important;
  float: left;
}
.feedback-form-wapper .form-group{
  padding: 5px;
  padding-bottom: 10px;
}
.feedback-form-wapper .field-required:after{
  position: absolute;
  padding-left: 2px;
}
.generated_form_table th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  top: 0;
}
.releiving-approval-btn {
  height: 30px;
  width: 34px;
  display: inline-block;
  padding: 0 !important;
}
span.manager_name {
  font-weight: 600;
  margin-right: 5px;
}



::-ms-reveal {
    display: none;
}
.repots_tab .tab_btn {
  border: 1px solid #1d4354;
  margin-right: 10px;
  color: #1d4354;
}

.repots_tab .tab_btn.active {
  background: #1d4354;
}
.repots_tab .btn-default.active {
  background: #1d4354;
  color:white;
}
.repots_tab .select-group .input-group-addon {
  background: none;
}

.repots_tab .select-group select {
  border-left: 0;
  padding-left: 0;
  box-shadow: none;
}

.repots_tab .download_icon {
  background: #1d4354;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.repots_tab .download_icon:focus,
.repots_tab select:focus {
  box-shadow: none;
}

.repots_tab .download_file {
  /* width: 120px;
  margin-left: auto; */
  display: flex;
  justify-content: end;
}

.report_table {
  margin-top: 25px;
  /*            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
  background: #ffff;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}

.report_table td,
.report_table th {
  padding: 10px 22px;
  border-left: 1px solid #c1c1c1;
  text-align: center;
}

.report_table .holiday {
   /* background: #75c590;  */
  background: #00aba9;
}

.report_table .present {
  background: #75c590;
}

.report_table tr th:first-child,
.report_table tr td:first-child {
  border-left: 0;
}

.report_table tr th:last-child,
.report_table tr td:last-child {
  border-right: 0;
}

.report_table tr th {
  border-bottom-color: #c1c1c1 !important;
}

.report_table tbody tr:last-child td {
  border-bottom: 0;
}

.report_table .full_leave {
  background: #faaf19;
}

.report_table .half_leave {
  background: #7ab3e1;
}
.report_table .absent {
  background: #b91515;
}

.define_leaves_color li {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  font-weight: 500;
}

.define_leaves_color li span:before {
  content: "";
  position: absolute;
  left: 11px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1d4354;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.define_leaves_color li span.yellow:before {
  background: #faaf19;
}

.define_leaves_color li span.blue:before {
  background: #7ab3e1;
}

.define_leaves_color li span.green:before {
  background: #6fbb89;
}
.define_leaves_color li span.holidayColor:before {
  background: #00aba9;
}
.define_leaves_color li span.red:before {
  background: #ca0002;
}

.dataTables_wrapper {
  margin-top: 20px;
}

.dataTables_wrapper .report_table {
  margin: 24px 0 !important;
}

.dataTables_wrapper .page-item.active .page-link {
  background-color: #1d4354;
  border-color: #1d4354;
  color: #fff;
}

.report_table_main .dataTables_wrapper .col-sm-12 {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .page-item .page-link {
  color: #1d4354;
}
#monthly{
  table-layout: fixed;
  width: 100%;
  *margin-left: -100px; /*ie7*/
}
.table-outer{
  position: relative;
}
.table-inner {
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 300px;
}

#monthly td, #monthly th
{vertical-align: top;
  border-top: 1px solid #ccc;
  padding: 10px;
  
}

#monthly tr th:first-child, #monthly tr td:first-child {
  position: absolute;
  margin-left: -300px;
  width: 50px;
  border-left: 1px solid #ccc;
  margin-top: -1px;
}

#monthly tr th:nth-child(2), #monthly tr td:nth-child(2) {
  position: absolute;
  *position: relative;
  margin-left: -250px;
  width: 50px;
  margin-top: -1px;

}

#monthly tr th:nth-child(3), #monthly tr td:nth-child(3) {
  position: absolute;
  *position: relative;
  margin-left: -200px;
  width: 200px;
  margin-top: -1px;

} 
#monthly tr:last-child td {
  border-bottom: 1px solid #ccc;


} 
.category-repots-tab .tab_btn {
  margin-bottom: 10px;
  white-space: nowrap;
}
#monthly td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .category-repots-tab #pills-tab {
  flex-wrap: nowrap;
  max-width: 1100px;
  overflow-x: scroll;
  scrollbar-width: thin;
} */
/* 
Custom scrollbar style  */
/* ::-webkit-scrollbar {
  height: 6px;              /* height of horizontal scrollbar ← You're missing this 
  width: 6px;               /* width of vertical scrollbar 
  border: 1px solid #d5d5d5;
  background-color: gray;
} 

::-webkit-scrollbar-track {  
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {  
  background: #b0b0b0;
} 
 /* Custom scrollbar style end */
.leaves-report-table #monthly {
  table-layout: auto;
} 

@media screen and (max-width: 991px) {
  .repots_tab .download_file {
    margin-left: 0;
  }

  .define_leaves_color ul {
    margin-left: -11px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: left;
    margin-bottom: 10px;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: flex-start !important;
  }
}
